import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import HiroKids from "../../components/Portfolio/HiroKids"

const HiroKidsPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="Home" keywords={[`BMR`, `labels`]} />
    <HiroKids />
  </Layout>
)

export default HiroKidsPage
